
import Vue from "vue";
import UserAchievements from "@/components/UserAchievements.vue";
import MentorcastInfo from "@/components/Mentorcast/MentorcastInfo.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorcastJoinPage",
  components: {
    UserAchievements,
    MentorcastInfo
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentorcast: "mentorcast/getMentorcast"
    })
  },
  mounted(): void {
    this.$store.dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id);
  }
});
