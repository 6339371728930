
import Vue from "vue";
import { InformationData, Mentorcast } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorcastInfo",
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    }
  },
  data() {
    return {
      paymentBtnLoading: false,
      selectedPaymentMethod: null,
      data: {
        payment_method_id: ""
      },
      cardTypeOptions: [
        {
          label: (this as any).$t("mentorcastHero.cardTypeOptionDebitCart"),
          value: "visa",
          icon: "visa"
        },
        {
          label: (this as any).$t("mentorcastHero.cardTypeOptionDebitCart"),
          value: "paypal",
          icon: "paypal"
        },
        {
          label: (this as any).$t("mentorcastHero.cardTypeOptionDebitCart"),
          value: "mastercard",
          icon: "mastercard"
        }
      ],
      currentTime: {},
      // Mentorcast information table data
      infoData: [
        {
          name: "start_in",
          label: (this as any).$t("mentorcastHero.infoDataStartIn"),
          icon: "profile",
          value: null
        },
        {
          name: "max_seats",
          label: (this as any).$t("mentorcastHero.infoDataAvailableSeats"),
          icon: "seats",
          value: null
        },
        {
          name: "duration",
          label: (this as any).$t("mentorcastHero.infoDataSessionDuration"),
          icon: "time-circle",
          value: null
        },
        {
          name: "seat_price",
          label: (this as any).$t("mentorcastHero.labelPricePerSeat"),
          icon: "price",
          value: null
        },
        {
          name: "seatNumber",
          label: (this as any).$t("mentorcastHero.infoDataSeatNumber"),
          icon: "seat-checked",
          value: null
        },
        {
          name: "payment",
          label: (this as any).$t("mentorcastHero.infoDataPayment"),
          icon: "payment",
          value: null
        },
        {
          name: "platform",
          label: (this as any).$t("mentorcastHero.infoDataPlatform"),
          icon: "platform",
          value: null
        }
      ] as InformationData[]
    };
  },
  computed: {
    ...mapGetters({
      paymentMethods: "payment/getPaymentMethods"
    })
  },
  watch: {
    mentorcast() {
      for (const key of Object.keys(this.mentorcast)) {
        const index = this.infoData.findIndex(i => i.name === key);
        if (index > -1) {
          if (key === "start_in") {
            const start_in = `${this.mentorcast.start_date} ${this.mentorcast.start_time}`;
            setTimeout(() => this.countdown(start_in, this.infoData[index]), 1);
            continue;
          }
          if (key === "duration") {
            this.infoData[index].value =
              this.mentorcast[key as keyof Mentorcast] + " minutes";
            continue;
          }
          this.infoData[index].value = this.mentorcast[key as keyof Mentorcast];
        }
      }
    }
  },
  created(): void {
    // const cond = "row" in this.$route.params && "column" in this.$route.params;
    // if (!cond) {
    //   this.$router.push(`/mentorcasts`)
    // }
    this.infoData[4].value = `R${this.$route.params.row} C${this.$route.params.column}`;
    this.$store.dispatch("payment/GET_PAYMENT_METHODS");
  },
  methods: {
    getIconType(type: string) {
      if (this.cardTypeOptions.find(i => i.value === type)) {
        //@ts-ignore
        return this.cardTypeOptions.find(i => i.value === type).icon;
      }
      return "paypal";
    },
    // Calculate how much time to start mentorcast
    countdown(deadline: string, date: InformationData) {
      const t = Date.parse(deadline) - Date.parse(new Date().toString());
      const seconds = Math.floor((t / 1000) % 60);
      const minutes = Math.floor((t / 1000 / 60) % 60);
      const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      if (t > 0) {
        const currentTime = {
          total: t,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        };
        date.value = this.currentTime
          ? `${currentTime.days ? currentTime.days + " days " : ""} ${(
              "0" + currentTime.hours
            ).slice(-2)}:${("0" + currentTime.minutes).slice(-2)}:${(
              "0" + currentTime.seconds
            ).slice(-2)}`
          : "";
        setTimeout(() => this.countdown(deadline, date), 1000);
      } else {
        this.currentTime = {};
      }
    },
    // Select payment method
    selectPaymentMethod() {
      const index = this.paymentMethods.findIndex(
        (i: any) => i.id === this.data.payment_method_id
      );
      if (index > -1) {
        this.selectedPaymentMethod = this.paymentMethods[index];
        (this.$refs["select-payment-method-modal"] as Vue & {
          hide: () => boolean;
        }).hide();
      }
    },
    onJoin() {
      this.paymentBtnLoading = true;

      this.$store
        .dispatch("booking/JOIN_MENTORCAST", {
          id: this.mentorcast.id,
          ...this.data
        })
        .then(() => {
          this.paymentBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("mentorcastHero.mentorcastJoinSuccessMessage")
          );
          this.$router.push(`/mentorcasts/${this.mentorcast.id}/rating`);
        })
        .catch(error => {
          this.paymentBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    }
  }
});
